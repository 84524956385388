<style scoped lang="scss">
.curriculum {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.curriculum-search {
  flex: none;
}

.curriculum-table {
  flex: 1;
  overflow: hidden;

  .table_center {
    height: calc(100% - 60px);
    overflow-y: scroll;
  }
}

.look-btn {
  width: 60px;
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: #6340c8;
  background-color: #f2edff;
  border-radius: 6px;
  border: solid 1px #c0adf9;

  &:hover {
    color: #ffffff;
    background-color: #6340c8;
    border: 1px solid #6340c8;

    &:before,
    &:after {
      background: #6340c8;
    }
  }
}

// 分页
.paging {
  padding: 10px 0;
  text-align: right;
}

// 新增课程
.new-courses {
  width: 100%;
  height: 68px;
  line-height: 68px;
  background-color: #f6f7fc;
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  position: relative;
  text-indent: 36px;
}

.newcourses {
  .new-courses-content {
    background: #ffffff;
    padding: 50px 90px 43px;
    box-sizing: border-box;
  }
}

.new-courses::after {
  content: "";
  width: 5px;
  height: 18px;
  background-color: #6340c8;
  border-radius: 2.5px;
  position: absolute;
  top: 26px;
  left: 25px;
}

::v-deep {
  .newcourses .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }

  .curriculum-search .el-input--medium .el-input__inner {
    min-width: 250px;
    // height: 50px;
    // line-height: 50px;
    background: #f1f2f8;
    border-radius: 10px;
    border: none;
  }

  // .curriculum-search .el-form-item--medium .el-form-item__label {
  //   line-height: 50px;
  // }
  .curriculum-search .el-form--inline .el-form-item {
    margin-right: 19px;
  }


}

// 新增课程
::v-deep {
  .new-courses-content .el-input--medium .el-input__inner {
    height: 46px;
    line-height: 46px;
    width: 393px;
    background: #f6f7fa;
    border: none;
    border-radius: 10px;
  }

  .new-courses-content .el-form-item--medium .el-form-item__label {
    line-height: 46px;
  }

  .el-dialog .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

// 师资卡片
.teacher-card {
  height: 6.25vw;
  margin: 6px 0 26px;
  display: flex;

  .card-list {
    width: 11.25vw;
    margin-right: 40px;
    height: 6.25vw;
    border-radius: 10px;
    display: flex;
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    cursor: pointer;

    .card-list-left {
      flex: 1;
      color: #ffffff;
      font-size: 0.73vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 1.35vw;

      .number {
        font-size: 1.56vw;
        font-weight: 600;
      }

      .title {
        margin-top: 0.6vw;
      }
    }

    .wave {
      position: absolute;
      left: -7vw;
      bottom: -8.8vw;
      width: 11.25vw;

      img {
        width: 200%;
      }
    }

    .card-list-right {
      width: 4.74vw;

      img {
        width: 4.74vw;
        height: 5.05vw;
        margin-top: 0.6vw;
      }
    }
  }


  .card-list:hover {
    box-shadow: 2px 3px 5px #ccc;
    transform: translateY(-2px);
  }

  .card-list:nth-child(1) {
    background: #f9b531;
  }

  .card-list:nth-child(2) {
    background: #f7657c;
  }

  .card-list:nth-child(3) {
    background: #472697;
  }

  .card-list:nth-child(4) {
    background: #22ccb7;
  }

  .card-list:nth-child(5) {
    background: #c241b7;
  }

  .card-list:nth-child(6) {
    background: #26b9ca;
  }

}

// 状态
.normal {
  font-size: 14px;
  color: #40c84a;
}

.unusual {
  font-size: 14px;
  color: #e53f32;
}
</style>
<template>
  <section class="curriculum">
    <div class="curriculum-search">
      <el-form :inline="true" :model="searchform" class="demo-form-inline">
        <el-form-item label="">
          <el-select filterable remote :remote-method="getSchoolList" :loading="loading" v-model="searchform.scsch_id"
            placeholder="请选择学校" clearable="true">
            <el-option v-for="item in school_list" :key="item.value" :label="item.key" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="">
          <el-input v-model="searchform.query_word" placeholder="请输入关键字"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="init('search')" class="lightpurple-btn">搜索</el-button>
          <el-button type="primary" @click="handelexport"  v-loading="btnLoad" class="lightyellow-btn">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="teacher-card">
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.tea1_allcount" :duration="3000"></countTo>位
          </p>
          <p class="title">教师总数</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.tea2_allcount" :duration="3000"></countTo>位
          </p>
          <p class="title">正高级教师</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.tea3_allcount" :duration="3000"></countTo>位
          </p>
          <p class="title">高级教师</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.tea4_allcount" :duration="3000"></countTo>位
          </p>
          <p class="title">一级教师</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.tea5_allcount" :duration="3000"></countTo>位
          </p>
          <p class="title">二级教师</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.tea6_allcount" :duration="3000"></countTo>位
          </p>
          <p class="title">三级教师</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
    </div>

    <div class="curriculum-table">
      <template>
        <div class="table_center">
          <el-table :data="tableData" style="width: 100%" :header-cell-style="{
            background: '#E8EAF3',
            color: '#1a1a1b',
            'text-align': 'center',
            fontWeight: 500,
          }">

            <el-table-column prop="teuser_id" label="ID"> </el-table-column>
            <el-table-column prop="teuse_name" label="教师姓名"> </el-table-column>
            <el-table-column label="身份">
              <template slot-scope="scope">
                <span>{{
                  scope.row.teuse_ide_identity == 10 ? "班主任" : scope.row.teuse_ide_identity == 20? "授课教师":'-' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="teuse_province_city_area" label="省/市/区"> </el-table-column>
            <el-table-column prop="scsch_name" label="学校"> </el-table-column>
            <el-table-column prop="create_time" label="添加时间"> </el-table-column>
            <el-table-column prop="sysment" label="类型">
              <template slot-scope="scope">
                <p>{{ scope.row.scsch_type == 10 ? "小学" : scope.row.scsch_type == 20 ? "初中" : scope.row.scsch_type == 30 ?
                  "高中" : scope.row.scsch_type == 40 ?"职高":'未知' }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <span :class="{ normal: scope.row.teuse_status == 10, unusual: scope.row.teuse_status == 20 }">{{
                  scope.row.teuse_status == 10 ? "启用" : "禁用" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="primary" class="look-btn" @click="handelinfo(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
            next-text="下一页" layout=" pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { teauserList,exportData} from "@api/teacher";
import { getSchSchoolKeyVal } from "@api/SchSchool";
import countTo from "vue-count-to";
export default {
  name: "",
  components: { countTo },
  data() {
    return {
      currentPage: 1,
      btnLoad:false,
      total: 0,
      searchform: {
        pageindex: 1,
        pagesize: 10,
      },
      school_list: [],
      // 表格数据
      tableData: [],
      loading: false,
      // 新增课程
      dialogTableVisible: false,

      newcoursesData: {},
    };
  },

  mounted() { },
  created() {
    this.init();
  },
  methods: {
      /*导出 */
      async handelexport(){
      this.btnLoad = true;
            let { data } = await exportData(this.searchform);

            this.btnLoad = false;
            if (data.full_file_url) {
                this.$fileDownload(data.full_file_url)
            };
    },
    // 修改当前
    handleCurrentChange() {
      this.searchform.pageindex = this.currentPage;
      this.init()
    },
    /**初始化 */
    async init(val) {
      if (val == "search") {
        this.searchform.pageindex = 1;
      }
      const res = await teauserList(this.searchform);
      if (res.status == 200) {
        this.tableData = res.data.data;
        this.total = res.data.allcount;
        this.newcoursesData = res.data;
      }
      this.getSchoolList();
    },
    async getSchoolList(query) {
      this.loading = true;
      const res = await getSchSchoolKeyVal("scsch_name", "scsch_id", "", query);
      if (res.status == 200) {
        this.school_list = res.data;
        this.loading = false;
      }
    },
    handelinfo(row) {
      this.$router.push({ name: 'TEACHER_INFO', params: { id: row.teuser_id } });
    }
  },
};
</script>
