import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const TEACHER = "/education/teauser/";

/**
 * 教师列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function teauserList(params) {
  try {
    return await API.post(TEACHER + "list", {
      ...params,
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}
/**
 * 教师详情
 * @param {object} params
 * @param {object} pages
 */
export async function teauserInfo(params) {
    try {
      return await API.post(TEACHER + "info",params);
    } catch (error) {
      return error;
    }
  }
/**
 * 导出
 * @param {object} params
 * @param {object} pages
 */
export async function exportData(params) {
    try {
      return await API.post(TEACHER + "export",params);
    } catch (error) {
      return error;
    }
  }

  